import React, { useEffect } from 'react'
import EmailModal from '../components/EmailModal'
import Breadcrumbs from '../components/Breadcrumbs'
import FurtherReading from '../components/FurtherReading'
import { graphql } from 'gatsby'
import RelatedResources from '../components/RelatedResources'
import Layout from '../components/Layout'
import ResourceMeta from '../components/ResourceMeta'
import Seo from '../components/Seo'
import '../styles/content.scss'
import '../styles/resource.scss'


export default function ResourceTemplate({ data }) {
    const resource = data.wpMereResource
    const image = resource.featuredImage?.node.mediaDetails?.sizes.find(size => size.name === 'large')?.sourceUrl
    const SEOImage = resource?.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src
    const relatedBook = resource.relatedBookField.relatedBook
    const isBook = resource.resourceTypes.nodes.find(node => node.slug === 'books')
    const relatedResources = resource.bookFields.relatedResources
    const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' }
    const pubDate = new Date(resource.dateGmt)
    const url = `https://bibletolife.com${resource.uri}`
    const authors = resource.multiauthor.nodes
    let modDate

    if (resource.modifiedGmt) {
        modDate = new Date(resource.modifiedGmt)
    }

    function formatFootnotes() {
        const contentContainer = document.getElementsByClassName('resource-container__content__inner')[0]
        const footnotes = document.querySelectorAll('.modern-footnotes-footnote__note')
        const markers = document.querySelectorAll('.modern-footnotes-footnote')

        // move footnote spans to bottom
        footnotes.forEach(function (footnote, index) {
            const newNote = document.createElement('div')
            const noteNumber = footnote.getAttribute('data-mfn')
            const footnoteID = footnote.id
            newNote.className = 'footnote'
            newNote.textContent = footnote.textContent
            footnote.remove()
            newNote.id = footnoteID
            const numberSpan = document.createElement('span')
            numberSpan.textContent = noteNumber
            newNote.prepend(numberSpan)
            contentContainer.appendChild(newNote)
        })
        //when superscript is clicked, jump to footnote at bottom
        markers.forEach(function (marker, index) {
            marker.addEventListener('click', function (e) {
                e.preventDefault()
                const footnoteID = marker.getAttribute('data-mfn-post-scope')
                const thisFootnote = document.getElementById('mfn-content-' + footnoteID)
                thisFootnote.scrollIntoView({ behavior: "smooth" })
            })
        })
    }

    useEffect(() => {
        formatFootnotes()
        // const insert = document.createElement("ins")
        // insert.className = "adsbygoogle"
        // insert.style.display = "block"
        // insert.setAttribute("data-ad-client", "ca-pub-6911957349585014")
        // insert.setAttribute("data-ad-slot", "3528199047")
        // insert.setAttribute("data-ad-format", "auto")
        // insert.setAttribute("data-adtest", "on")
        // insert.setAttribute("data-full-width-responsive", "true")

        // const parent = document.getElementsByClassName('resource-container__content__inner')[0]
        // let targetParagraph = parent.getElementsByTagName("p")[2]
        // const targetSibling = targetParagraph.nextSibling

        // if (targetParagraph && targetSibling) {
        //     targetParagraph.parentNode.insertBefore(insert, targetSibling)
        // } else if (targetParagraph) {
        //     targetParagraph.parentNode.appendChild(insert)
        // } else {
        //     parent.appendChild(insert)
        // }
        // try {
        //     (window.adsbygoogle = window.adsbygoogle || []).push({});
        // }
        // catch (e) {
        // }
    }, [])

    console.log('related resources: ', relatedResources)
    return (
        <Layout>
            <Seo
                title={resource.title}
                description={resource.excerpt.replace(/(<([^>]+)>)/gi, "")}
                article={true}
                image={SEOImage ? `https://bibletolife.com${SEOImage}` : image}
                topics={resource.topics}
                series={resource.series}
                authors={authors}
            />
            <Breadcrumbs isResource={true}
                topics={resource.topics.nodes}
                series={resource.series.nodes}
                scriptures={resource.scriptures.nodes}
                type={resource.resourceTypes.nodes}
                title={resource.title}
            />
            <div className={`resource-container ${isBook ? "resource-container--book" : ""}`}>
                <h1>{resource.title}</h1>
                {!isBook &&
                    <ResourceMeta authors={authors} topics={resource.topics.nodes}
                        perspectives={resource.perspectives.nodes} />
                }
                <div className="resource-container__content">
                    {image && resource.resourceTypes.nodes[0].name !== 'Books' &&
                        <img src={image} alt={`header for ${resource.title}`} />
                    }
                    <div className="resource-container__content__inner">
                        {isBook &&
                            <div className="resource-container__content__book-info">
                                <img
                                    src={resource.bookFields.bookCoverImage.mediaDetails?.sizes.find(size => size.name === 'large')?.sourceUrl}
                                    alt={`Cover for ${resource.title}`} />
                                <div
                                    className="resource-container__content__book-info__author">By: <span>{resource.bookFields.bookAuthor}</span>
                                </div>
                                {resource.bookFields.buyAtMoodyLink &&
                                    <a href={resource.bookFields.buyAtMoodyLink} rel="noreferrer" target="_blank" className="btn btn--primary">Buy at Moody
                                        Publishers</a>
                                }
                                {resource.bookFields.buyAtAmazonLink &&
                                    <a href={resource.bookFields.buyAtAmazonLink} rel="noreferrer" target="_blank" className="btn btn--secondary">Buy at
                                        Amazon</a>
                                }
                                {resource.bookFields.buyAtChristianBookLink &&
                                    <a href={resource.bookFields.buyAtChristianBookLink} rel="noreferrer" target="_blank" className="btn btn--secondary">Buy at
                                        ChristianBook.com</a>
                                }
                                <span><em>Or wherever good books are sold.</em></span>
                            </div>
                        }
                        <div dangerouslySetInnerHTML={{ __html: resource.content }} />
                        {!isBook &&
                            <div className="resource-container__content__date">
                                <span>Published on {pubDate.toLocaleDateString("en-US", dateOptions)}.  </span>
                                {modDate > pubDate &&
                                    <span>Modified on {modDate.toLocaleDateString("en-US", dateOptions)}.</span>
                                }
                            </div>
                        }
                    </div>
                    {relatedBook && !isBook &&
                        <FurtherReading title={relatedBook.title}
                            author={relatedBook.bookFields?.bookAuthor}
                            slug={relatedBook.slug}
                            link={relatedBook.uri}
                            excerpt={relatedBook.excerpt}
                            image={relatedBook.bookFields?.bookCoverImage.mediaDetails?.sizes.find(size => size.name === 'large')?.sourceUrl} />
                    }
                </div>
            </div>
            {relatedResources && isBook &&
                <RelatedResources items={relatedResources} />
            }
            <EmailModal />
        </Layout>
    )
}

export const query = graphql`
    query($slug: String!) {
        wpMereResource(slug: { eq: $slug } ) {
            title
            content
            slug
            uri
            date
            dateGmt
            modifiedGmt
            excerpt
            topics {
                nodes {
                    slug
                    name
                    databaseId
                    wpParent {
                        node {
                            databaseId
                            name
                            slug
                        }
                    }
                }
            }
            series {
                nodes {
                    name
                    slug
                }
            }
            scriptures {
                nodes {
                    name
                    slug
                }
            }
            resourceTypes {
                nodes {
                    name
                    slug
                }
            }
            multiauthor {
                nodes {
                    slug
                    name
                }
            }
            perspectives {
                nodes {
                    name
                    slug
                }
            }
            resourceEvents {
                nodes {
                    name
                    slug
                }
            }
            featuredImage {
                node {
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 900
                                formats: AUTO
                            )
                        }
                    }
                    mediaDetails {
                        sizes {
                            name
                            sourceUrl
                        }
                    }
                }
            }
            bookFields {
                bookAuthor
                buyAtAmazonLink
                buyAtMoodyLink
                buyAtChristianBookLink
                bookCoverImage {
                    mediaDetails {
                        sizes {
                            name
                            sourceUrl
                        }
                    }
                }
                relatedResources {
                    ... on WpMere_resource {
                        id
                        title
                        slug
                        uri
                        excerpt
                        featuredImage {
                            node {
                                mediaDetails {
                                    sizes {
                                        name
                                        sourceUrl
                                    }
                                }
                            }
                        }
                    }
                }
            }
            relatedBookField {
                relatedBook {
                    ... on WpMere_resource {
                        id
                        title
                        slug
                        excerpt
                        bookFields {
                            bookAuthor
                            bookCoverImage {
                                mediaDetails {
                                    sizes {
                                        sourceUrl
                                        name
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
