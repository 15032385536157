import React from 'react'
import '../styles/further-reading.scss'

const FurtherReading = (props) => {
    const truncate = (str, max, suffix) =>
         `${str.substr(0, str.substr(0, max - suffix.length).lastIndexOf(' '))}${suffix}`
    return (
        <section className="further-reading">
            <div className="further-reading__content">
                <span className="further-reading__content__pretitle">For Further Reading: </span>
                <h4 className="further-reading__content__title"><a href={'/resources/books/' + props.slug}>{props.title}</a></h4>
                <p>by {props.author}</p>
                {props.excerpt &&
                    <div className="further-reading__content__excerpt" dangerouslySetInnerHTML={{__html: truncate(props.excerpt, 150, '...')}} />
                }
            </div>
            <a className="further-reading__cover" href={'/resources/books/' + props.slug}><img src={props.image} alt={`book cover for ${props.title}`} /></a>
        </section>
    )
}

export default FurtherReading