import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronRight} from '@fortawesome/pro-light-svg-icons'

const ResourceMeta = (props) => {
    const parentTopics = []
    let topicCount = 0

    if (props.topics) {
        props.topics.map((term) =>
            term.wpParent?.node ?
                parentTopics.push(term.wpParent.node.databaseId)
                : ''
        )
    }

    return (
        <div className="resource-container__meta">
            {props.authors &&
            <div className="resource-container__meta__term">
                <div
                    className="resource-container__meta__term__label">
                    By:
                </div>
                <div className="resource-container__meta__term__label__terms">
                    {props.authors.map((term, index) =>
                            <span>
                                {props.authors.length - 1 === index && index !== 0 && " and "}
                                {props.authors.length - 1 !== index && index !== 0 && ", "}
                                {term.name}
                                {props.authors.length -1 !== index && "\u00a0"}
                            </span>
                    )}
                </div>
            </div>
            }
            {props.topics &&
            <div className="resource-container__meta__term">
                <div
                    className="resource-container__meta__term__label">
                    Topic:
                </div>
                <div className="resource-container__meta__term__label__terms">
                    {props.topics.map((term, index) => {
                        if (term.wpParent) {
                            topicCount++
                            return (
                                <div className="resource-container__meta__term__label__terms__pair">
                                    {
                                        topicCount > 1 &&
                                        <span>,&nbsp;</span>
                                    }
                                    <a href={"/resources/topics/" + term.wpParent.node?.slug}>
                                        {term.wpParent.node?.name}
                                    </a>
                                    <span className="resource-container__meta__term__label__terms__separator">
                                            <FontAwesomeIcon icon={faChevronRight}/>
                                        </span>
                                    <a href={"/resources/topics/" + term.slug}>
                                        {term.name}
                                    </a>
                                </div>
                            )
                        } else if (!parentTopics.includes(term.databaseId)) {
                            topicCount++
                            return (
                                <>
                                    {
                                        topicCount > 1 &&
                                        <span>,&nbsp;</span>
                                    }
                                    <a href={"/resources/topics/" + term.slug}>{term.name}</a>
                                </>
                            )
                        }
                    })}
                </div>
            </div>
            }
            {props.perspectives &&
            <div className="resource-container__meta__term">
                <div
                    className="resource-container__meta__term__label">
                    Perspective:
                </div>
                <div className="resource-container__meta__term__label__terms--perspective">
                    {props.perspectives.map((term, index) =>
                        <a key={index} href={'/resources/perspectives/' + term.slug}>{term.name}</a>
                    )}
                </div>
            </div>
            }
        </div>
    )
}

export default ResourceMeta