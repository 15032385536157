import React from 'react'

const Card = (props) => {
    const card = props.data
    let image
    if (props.isBooks) {
        image = card.bookFields.bookCoverImage.mediaDetails.sizes.find(size => size.name === 'large')?.sourceUrl
    } else if (card.featuredImage) {
        image = card.featuredImage.node.mediaDetails.sizes.find(size => size.name === 'medium')?.sourceUrl
    } else {
        image = ''
    }
    return (
        <a key={props.key} href={card.uri} className="card-grid__card"
           data-scripture={card.scripture?.nodes[0]?.slug}>
                <span className="card-grid__card__top">
                    {props.isBooks ? (
                            <img src={image} alt={`book cover for ${card.title}`} className="card-grid__card__book-image"/>
                        ) :
                        (
                            <div className="card-grid__card__image"
                                 style={{backgroundImage: `url("${image}")`}}/>
                        )}
                    <span className="card-grid__card__title">{card.title}</span>
                </span>
            {!props.hideMeta &&
            <div className="card-grid__card__meta">
                {!props.isBooks && card.resourceTypes &&
                <div className="card-grid__card__meta__type">{card.resourceTypes.nodes[0]?.name}</div>
                }
                {card.multiauthor &&
                <div className="card-grid__card__meta__author">
                    By {card.multiauthor.nodes.map((term, index) =>
                    <span>
                        {card.multiauthor.nodes.length - 1 === index && index !== 0 && " and "}
                        {card.multiauthor.nodes.length - 1 !== index && index !== 0 && ", "}
                        <a key={index} href={'/resources/authors/' + term.slug} dangerouslySetInnerHTML={{__html: term.name}} />
                        {card.multiauthor.nodes.length - 1 !== index && "\u00a0"}
                    </span>
                )}
                </div>
                }
            </div>
            }
        </a>
    )
}

export default Card