import React from 'react'
import Card from './Card'
import '../styles/card-grid.scss'

const RelatedResources = (props) => {

    const items = props.items

    return (
        <div className="container">
            <h4>Related Resources: </h4>
            <div className="card-grid">
                {items.map((item, index) => (
                    <Card data={item} hideMeta={true}/>
                ))}
            </div>
        </div>
    )
}

export default RelatedResources